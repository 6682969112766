import React, {useState} from 'react';
import './App.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image  from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import jsonp from 'jsonp';
import Spinner from 'react-bootstrap/Spinner';
import Carousel from 'react-bootstrap/Carousel';
import Accordion from 'react-bootstrap/Accordion';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { Router, Link } from "@reach/router";
import Privacy from './Components/Privacy'
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Css/home.scss';

function App() {

  const [formSubmitting, setFormSubmitting] = useState(false);
  const [signedUp, setSignedUp] = useState(false);
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let privacy = params.get('privacy');

  const [show, setShow] = useState(false);
  const handleClose = () =>{ 
    setShow(false)
    setSignedUp(false)
  };

  const Borders = () => {
    return(
      <>
        <div className='border-h-t'></div>
        <div className='border-h-b'></div>
        <div className='border-v-l'></div>
        <div className='border-v-r'></div>
      </>
      
    )
  }
  const ThisForm = (props) => {
    let id = props.id;
    if(formSubmitting) 
      {return (
        <div className='form-message'>
          <Spinner animation="border" variant="danger" />
          <h1>Wait a second, signing you up!</h1>
        </div>)
      }
    if(signedUp)
    {
      setShow(true)
      
      return (
        <>
          <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>FREE DIGITAL POSTER DOWNLOAD</Modal.Title>
          </Modal.Header>
          <Modal.Body>If you don't get the welcome email in the next few minutes, check your junk mail.</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <div className='form-message'>
          <h1>Thank you! You've been signed up!</h1>
        </div>
        </>
      )
    }
    return(
      <LazyLoadComponent>
        
        <Form onSubmit={(e) => {
        
        e.preventDefault();
        setFormSubmitting(true);
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('s');
        const utm = urlParams.get('utm_campaign');
        let source = '';
        if(myParam) source = '&group[52961][1]'; //google ads
        if(utm) source = '&group[52941][2]=true'; //fb ads

        const formData = new FormData(e.target);
        const details = Object.fromEntries(formData.entries());
        const url = `https://theveincomic.us9.list-manage.com/subscribe/post-json?u=e664375ffbd80dd227654d219&amp;id=e0c485b25a&amp;f_id=001605e1f0&EMAIL=${details.EMAIL}&FNAME=${details.FNAME}&LNAME=${details.LNAME}${source}`

        jsonp(url, { param: 'c' }, (_, data) => {
          const { msg, result } = data
          // do something with response
          setSignedUp(true);
          setFormSubmitting(false);
          
      });
      }}>
        <Form.Group className="mb-3" controlId={`${id}Email`}>
          <Form.Control type="email" name="EMAIL" required />
          <Form.Label>Email address</Form.Label>
        </Form.Group>

        <Form.Group className="mb-3" controlId={`${id}FName`}>
          <Form.Control type="text" name='FNAME' />
          <Form.Label>First Name</Form.Label>
        </Form.Group>

        <Form.Group className="mb-3" controlId={`${id}LName`}>
          <Form.Control type="text" name='LNAME' />
          <Form.Label>Last Name</Form.Label>
        </Form.Group>

        <Button variant="primary" type="submit">
          Submit!!!
        </Button>
      </Form>
      </LazyLoadComponent>
      
    )
  }

  const TopForm = () => {
    return(
      <>
      <Container>
        <Row className='vein-content'>
            <Borders />
            <Col style={{position:'relative'}} xs={12} md={6}>
              <Image src='/images/mock-cover-web-small.webp' alt='vein comic cover sample' fluid className='hidden-desktop' style={{margin: '-40px -44px 0 -44px', width: 'calc(100% + 88px)', maxWidth: 'calc(100% + 88px)'}} />
              <div className='hidden-mobile' style={{backgroundImage:'url(/images/mock-cover-web-desktop.webp)', width:'calc(100% )', height:'calc(100% + 80px)', position:'absolute', left: '-40px', top: '-40px', backgroundSize: 'cover'}}></div>
              
             
            </Col>
            <Col xs={12} md={6} className='mb-5'>
              
              <p>THE VEIN, a sprawling body horror adventure comic about a young man with a symbiotic ally allowing him to traverse dimensions. But even with such power, he struggles to find identity and meaning in a bizarre and chaotic universe.</p>
              <p> THE VEIN; confronting unusual adversaries, exploring strange realities and expressing the human condition. </p>
              <p> Signup below to make sure you get all of the updates on the ISSUE #1 Kickstarter for THE VEIN.</p>

              {/* <ThisForm id='top' /> */}
          </Col>
          </Row>
      </Container>
      <Guts />
      </>
    )
  }
  const Guts = () => {
    return(<LazyLoadComponent>
      <Container>
        
        <Row className='vein-content carousel-wrapper' >
          <Borders />
          
          <Col xs={12} md={{span:12}} className='carousel-inner'>
          <Carousel className='hidden-mobile'>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="/images/slide-1.webp"
                alt="First slide"
                
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="/images/slide-2.webp"
                alt="Second slide"
              />

            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="/images/slide-3.webp"
                alt="Third slide"
              />

            </Carousel.Item>
          </Carousel>
          <Carousel className='hidden-desktop'>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="/images/slide-1-a.webp"
                alt="First mobile slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="/images/slide-1-b.webp"
                alt="second mobile slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="/images/slide-2-a.webp"
                alt="third mobile slide"
              />

            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="/images/slide-2-b.webp"
                alt="forth mobile slide"
              />

            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="/images/slide-3-a.webp"
                alt="fifth mobile slide"
              />

            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="/images/slide-3-b.webp"
                alt="sixth mobile slide"
              />

            </Carousel.Item>
          </Carousel>
          </Col>

          <Col xs={12}  md={{offset:1,span:10}}>
            <h1>Oooooh, look at the pretty pictures...</h1>
            <p>
            THE VEIN is an homage to the strange horror, action and sci fi pulp publications that have shaped our collective consciousness. Each page is a visual feast, filled with wild characters, hilarious action and copious amounts of blood and gore. 
            </p>
          </Col>    
        </Row>
        <Row style={{marginTop:'40px',padding:'0 40px'}}>
          <Col xs={12} md={4}  className='mb-5'>

            <Card style={{height:'100%'}}>
              <Borders />
              <Card.Img alt='Image of someone getting face ripped off' variant="top" src="/images/card-1.webp" />
              <Card.Body>
                <Card.Title><i>Layers</i> of Meaning!</Card.Title>
                <Card.Text>
                Peel back the layers of this story! Get it?! Because we think our comic filled with gratuitous gore and absurdist humor somehow has philosophical depth!
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={4} className='mb-5'>
            <Card style={{height:'100%'}}>
              <Borders />
              <Card.Img alt='Image of Loxwanna Chraacter' variant="top" src="/images/card-2.webp" />
              <Card.Body>
                <Card.Title>Characters that <i>ooze</i> personality!</Card.Title>
                <Card.Text>
                  A surreal supporting cast of characters you won’t be able to forget no matter how hard you try! Hmmm, maybe we should’ve gone with the “sticky situations” pun for this image instead…
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col  xs={12} md={4} className='mb-5'>
            <Card style={{height:'100%'}}>
              <Borders />
              <Card.Img variant="top" alt='Image of an emotional Character upside down' src="/images/card-3.webp" />
              <Card.Body>
                <Card.Title>Emotional Depth!</Card.Title>
                <Card.Text>
                  Why is this panel upside down? I'll tell you why, because of emotions. Did you know that you have deeper and more meaningful emotions when you're upside down? It's true!
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className='vein-content'>
          <Borders />
          <Col xs={12}>
            <h2>Why are you asking us questions?</h2>
          </Col>
          <Col xs={12} md={{offset:2, span:8}}>
          <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
              <Accordion.Header>Why are you making this book?</Accordion.Header>
              <Accordion.Body>
              <p>We have both collective and individual reasons for doing this. </p>
              <p>Individually, David does this because he is a masochist, Jasen is involved because he lost a bet, and Hans is compelled by court order.</p>
              <p>Collectively, we got together and decided that we all hate things like “free time,” “money,” “happiness” or even “a social life.” All that stuff is overrated.</p>

              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>When will the book be done?</Accordion.Header>
              <Accordion.Body>
              <p>
                OCTOBER at the LASTEST! We are 80% done with the art! It s live on Kickstarter now, and books will be finshed, printed, and shipped no later than NOVEMBER.
              </p>

              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>What are your influences?</Accordion.Header>
              <Accordion.Body>
                <p>David - horror films; Jasen - the occult; Hans - psychedelics.</p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>What is wrong with you guys?</Accordion.Header>
              <Accordion.Body>
                <p>Wow, man. You don’t have to be hurtful. We have feelings and stuff. Perhaps mostly negative ones associated with spite and cruelty, but they are still feelings nonetheless.</p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>Will there be Kickstarter Bonuses?</Accordion.Header>
              <Accordion.Body>
                <p>Of course! We even got artists who are better and smarter than us to contribute things. But you’ll have to sign up to the newsletter for updates, and actually support the campaign for those sweet, sparkly, gory and grotesque bonuses!</p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={{offset:2, span:8}}>
            <Image alt='Comic Panels' style={{position:'relative', bottom:'-40px'}} className='hidden-mobile' src='/images/3-panels.webp' fluid />
            <Image alt='Comic Panels' style={{position:'relative', bottom:'-40px'}} className='hidden-desktop' src='/images/3-panels-small.webp' fluid />
          </Col>
        </Row>
        <Row className='vein-content' style={{backgroundColor:'black',padding:'20px 40px', paddingBottom: '15px', marginBottom: '0'}}>
          <Borders />
          <Col xs={12}><h2 style={{color:'white',}}>Meet the deranged team behind this book.</h2></Col> 
        </Row>
        <Row    style={{marginTop:'28px',padding:'0 40px',}}>
          <Col xs={12} md={4} className='mb-5'>
            <Card style={{height:'100%'}}>
              <Borders />
              <Card.Body>
                <Image alt='Photo of David Le COmpte' roundedCircle src="/images/dave.webp" className='hidden-mobile' style={{width:'100px', float:'left', marginRight:'20px'}}  />
                <Image alt='Photo of David Le Compte' roundedCircle src="/images/dave.webp" className='hidden-desktop' style={{width:'100px', marginRight:'20px'}}  />
                <Card.Title>David Le Compte</Card.Title>
                <Card.Text>
                  All of this was David’s idea, he is mostly to blame, the other two guys are merely witless accomplices. The plot, the characters and the insane line art all derived from a fever dream David experienced after binging the entire Cronenberg filmography while amped up on pez & coke. He has greater physical strength than Hans and Jasen combined.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={4}  className='mb-5'>
            <Card style={{height:'100%'}}>
              <Borders />
              <Card.Body>
                <Image alt='Photo of Jasen T David' roundedCircle src="/images/jasen.webp" className='hidden-mobile' style={{width:'100px', float:'left', marginRight:'20px'}}  />
                <Image alt='Photo of Jasen T David' roundedCircle src="/images/jasen.webp" className='hidden-desktop' style={{width:'100px', marginRight:'20px'}}  />
                <Card.Title>Jasen T Davis</Card.Title>
                <Card.Text>
                  Jasen is an expert at Locution, which means “word stuff,” which you’d know if you read dictionaries instead of comics. He is responsible for translating David’s insane ramblings into coherent scripts. Jasen belongs to exactly three secret mystical societies. He’s been invited to join others but caps it at three because it’s a powerful number. His IQ is higher than David and Hans combined.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={4} className='mb-5'>
            <Card style={{height:'100%'}}>
              <Borders />
              <Card.Body>
                <Image alt='Photo of Hans Fink' roundedCircle src="/images/hans.webp" className='hidden-mobile' style={{width:'100px', float:'left', marginRight:'20px'}}  />
                <Image alt='Photo of Hans Fink' roundedCircle src="/images/hans.webp" className='hidden-desktop' style={{width:'100px',  marginRight:'20px'}}  />
                <Card.Title>Hans Fink</Card.Title>
                <Card.Text>
                  Hans wears nice sweater vests to hide the evil lurking deep within his heart. He may use cutting edge technology to vomit vivid sparkly rainbow colors all over David’s crazy line art, and assign typefaces to Jasen’s ludicrous scripts, but outside of those applications he thinks like a luddite and has a tendency to throw rocks at things he doesn’t understand. He is better looking than David and Jasen combined.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
       
        <Row className='vein-content'>
           <Borders />
           
           <Col xs={12}>
            <h2>Why are you still here?</h2>
            <h3>Go check out the Kickstarter </h3>
            <Button style={{display:'block', margin:'auto', width:'fit-content', fontWeight:'bold', fontSize:'x-large', textTransform:'uppercase'}} variant='danger' size='large' href='https://www.kickstarter.com/projects/davidlecomptecomics/the-vein'>Get Your Copy</Button>
            {/* <p style={{textAlign:'center'}}>I mean, if you'd like us to keep rambling on, you might as well sign up for our newsletter thing.</p> */}

           </Col>
           <Col xs={12} md={{offset:2, span:8}}>
            {/* <ThisForm id='bottom' /> */}
           </Col>
        </Row>
        <Row style={{padding:'40px', textAlign:'center'}}>
           <Col xs={12}>
            <Image alt='Abstract picture of Hands' style={{maxHeight:'350px', maxWidth: '100%'}} className='hidden-mobile' src='/images/hands.webp' />
            <Image alt='Abstract picture of Hands' style={{maxHeight:'350px', maxWidth: '100%'}} className='hidden-desktop' src='/images/hands-small.webp' />
           </Col>
           <Col xs={12}>
            <p style={{fontSize:'small', fontWeight:'bold', textTransform:'uppercase'}}>©David Le Compte - 2023</p>
           </Col>
        </Row>
      </Container>
      
    </LazyLoadComponent>
  )}



  return (
    <div className="App" style={{backgroundImage: 'url(/images/paper-bg-small.webp)', backgroundSize: 'contain'}}>
      <Container fluid className='hidden-mobile' >
        <Row className='mb-5 pt-5'>
            <Col xs={12} style={{padding:'0'}}><Image alt='desktop logo' className='hidden-mobile' src='/images/logo-banner.webp' fluid /></Col>
            <Col xs={12} style={{padding:'0'}}><h1 style={{textAlign:'center'}}>A Strange, Sci-fi, Body-Horror, <span style={{ color:'red'}}>COMIC Book</span></h1>
            <h2 style={{textAlign:'center'}}>Kickstarter is Live!</h2>
            <Button style={{display:'block', margin:'auto', width:'fit-content', fontWeight:'bold', fontSize:'x-large', textTransform:'uppercase'}} variant='danger' size='large' href='https://www.kickstarter.com/projects/davidlecomptecomics/the-vein'>Get Your Copy</Button>
            </Col>
          </Row>
        
      </Container>
      <Container fluid className='hidden-desktop' >
        <Row className='mb-5 pt-5 hidden-desktop'>
              <Col style={{padding:'0'}}>
                <Image style={{width:'calc(100% - 80px)', margin:'40px', marginTop:'0',}} alt='mobile logo' width='414px' className='hidden-desktop' src='/images/Logo.webp' fluid /></Col>
                <Col xs={12} style={{padding:'0'}}><h1 style={{textAlign:'center'}}>A Strange, Sci-fi, Body-Horror, <span style={{ color:'red'}}>COMIC Book</span></h1>
                <h2 style={{textAlign:'center'}}>Kickstarter is Live!</h2>
            <Button style={{display:'block', margin:'auto', width:'fit-content', fontWeight:'bold', fontSize:'x-large', textTransform:'uppercase'}} variant='danger' size='large' href='https://www.kickstarter.com/projects/davidlecomptecomics/the-vein'>Get Your Copy</Button>
            </Col>
          </Row>
      </Container>
      
        {privacy ?  <Privacy /> : <TopForm />}
      {/* <Router>
      </Router> */}
      
    </div>
  );
}

export default App;
